/**
 * Cards JS
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $serviceCard = $('.service-card');
        // sanity check
        if ($serviceCard.length < 1) {
            return;
        }

        var cardModule = (function() {

            var $cards = document.querySelectorAll('.service-card');

            var init = function() {

                function mouseEnterCard(e) {
                    var image = this.firstElementChild.firstElementChild;
                    TweenMax.to(image, 0.5, {
                        scale: 0.95,
                        ease: Sine.easeOut
                    });
                    TweenMax.to(this, 0.3, {
                        yPercent: -5,
                        ease: Sine.easeOut
                    });
                }

                function mouseLeaveCard(e) {
                    var image = this.firstElementChild.firstElementChild;
                    TweenMax.to(image, 0.5, {
                        scale: 1,
                        ease: Sine.easeOut
                    });
                    TweenMax.to(this, 0.3, {
                        yPercent: 0,
                        ease: Sine.easeOut
                    });
                }

                if (window.innerWidth >= 1100) {
                    for (var i = 0; i < $cards.length; i++) {
                        $cards[i].addEventListener('mouseenter', mouseEnterCard);
                        $cards[i].addEventListener('mouseleave', mouseLeaveCard);
                    }
                }
            };

            return {
                init: init
            };
        })();

        cardModule.init();

    }); // document.ready
})(jQuery);
