/**
 * Form interactions
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */
(function($) {

    var $document = $(document);
    $document.ready(init);

    /**
     * Start setting/binding document-ready stuff
     *
     * @since 1.0.0
     */
    function init() {

        var $body = $(document.body);
        var $checkboxes = $('[type="checkbox"], [type="radio"]');

        $checkboxes.each(checkCheckboxes);
        $checkboxes.on('change', checkCheckboxes);
        $body.on('update_checkout', checkCheckboxes);
    }


    /**
     * See if checkboxes and radios are checked, flagging a parent <label>
     *
     * @since 1.0.0
     */
    function checkCheckboxes() {

        $this = $(this);

        checked = $this.prop('checked');
        $label = $this.parent('label');

        if (checked) {
            $label.addClass('input-checked');
        } else {
            $label.removeClass('input-checked');
        }
    }

})(jQuery);
