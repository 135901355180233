/**
 * ScrollTo Button Functionality (for banner)
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $scrollTo = $('#scrollTo');
        // sanity check
        if ($scrollTo.length < 1) {
            return;
        }

        var scrollToModule = (function() {

            var init = function() {

                var scrollToButton = document.getElementById('scrollTo');

                var scrollToSection = function(e) {
                    e.preventDefault();
                    var banner = this.parentElement;
                    var bannerHeight = banner.getBoundingClientRect().height;
                    TweenMax.to(window, 1, {
                        scrollTo: {
                            y: bannerHeight,
                            autoKill: false
                        },
                        ease: Sine.easeInOut
                    });
                };

				// if larger than mobile breakpoint add hover effect to button
                if (window.innerWidth >= 1100) {

                    scrollToButton.addEventListener('mouseenter', function(e) {
                        var icon = this.firstElementChild;
                        TweenMax.to(icon, 0.3, {
                            yPercent: 25,
                            force3D: true,
                            ease: Sine.easeOut
                        });
                    });

                    scrollToButton.addEventListener('mouseleave', function(e) {
                        var icon = this.firstElementChild;
                        TweenMax.to(icon, 0.3, {
                            yPercent: 0,
                            force3D: true,
                            ease: Sine.easeOut
                        });
                    });

                }
				// on click call scrollToSection
                scrollToButton.addEventListener('click', scrollToSection);
            };

            return {
                init: init
            };
        })();

        scrollToModule.init();

    }); // document.ready
})(jQuery);
