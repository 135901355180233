/**
 * Page Loader Scripts
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $loader = $('.page-loader');
        // sanity check
        if ($loader.length < 1) {
            return;
        }

        var loaderModule = (function() {

            var $pageLoader = document.querySelector('#pageLoader');

            var init = function() {
				// animate loader out
                TweenMax.to($pageLoader, 0.3, {
                    delay: 2,
                    xPercent: -100,
                    force3D: true,
                    ease: Sine.easeOut
                });
				// after 3s set to display none	
                setTimeout(function() {
                    $pageLoader.classList.add('dispnone');
                }, 3000);

            };

            return {
                init: init
            };
        })();

        // if browser is Internet Explorer do not initialize the loaderModule
        // and do not display the loader
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            $loader.addClass('dispnone');
            // else init loaderModule
        } else {
            loaderModule.init();
        }

    }); // document.ready
})(jQuery);
