/**
 * Hover animations for navigation
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var hoversModule = (function() {

            var followAlongLinks = function() {

                var $primaryMenu = document.querySelector('#primary-menu');
                var $triggers = document.querySelectorAll('li.menu-item');
                var highlight = document.createElement('span');
                highlight.classList.add('link-highlight');
                $primaryMenu.appendChild(highlight);

                function highlightLink(e) {
                    e.preventDefault();
                    var linkCoords = this.getBoundingClientRect();
                    var coords = {
                        width: linkCoords.width,
                        height: linkCoords.height,
                        top: linkCoords.top,
                        left: linkCoords.left,
                    };
                    if (parseInt(this.children.length) < 2) {
                        TweenMax.to(highlight, 0.2, {
                            autoAlpha: 1,
                            width: coords.width,
                            left: coords.left,
                            ease: Sine.easeOut
                        });
                    } else {
                        TweenMax.to(highlight, 0.2, {
                            autoAlpha: 0,
                            ease: Sine.easeOut
                        });
                    }
                }
                if (window.innerWidth > 1100) {
                    $triggers.forEach(function(link) {
                        link.addEventListener('mouseenter', highlightLink);
                    });
                    $primaryMenu.addEventListener('mouseleave', function() {
                        TweenMax.to(highlight, 0.2, {
                            autoAlpha: 0,
                            ease: Sine.easeOut
                        });
                    });
                    $primaryMenu.addEventListener('mouseenter', function() {
                        TweenMax.to(highlight, 0.2, {
                            autoAlpha: 1,
                            ease: Sine.easeOut
                        });
                    });
                }
            };

            var navAnimations = function() {
                var $nav = document.querySelector('header[role="banner"]');
                var topOfNav = $nav.getBoundingClientRect().height;

                function fixNav() {
                    if (window.scrollY > topOfNav) {
                        $nav.classList.add('z-depth');
                    } else {
                        $nav.classList.remove('z-depth');
                    }
                }
                window.addEventListener('scroll', fixNav);
            };

            var init = function() {
				// call function to animate navigation 'follow-along' links on hover
                followAlongLinks();
				// call function to animate navigation after scrolling past header
                navAnimations();
            };

            return {
                init: init
            };
        })();

        // if browser is Internet Explorer do not initialize the hoversModule
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            return;
            // else init hoversModule
        } else {
            hoversModule.init();
        }


    }); // document.ready
})(jQuery);
