/**
 * Fade in Targets on scroll
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $fadeInTargetsL = $('.fade-in-target-left');
        var $fadeInTargetsR = $('.fade-in-target-right');
        var $fadeInTargets = $('.fade-in-target');
        // sanity check
        if ($fadeInTargets.length < 1 && $fadeInTargetsL.length < 1 && $fadeInTargetsR.length < 1) {
            return;
        }

        var fadeInTargets = (function() {

            var init = function() {
                var $fadeInTarget = document.querySelectorAll(".fade-in-target");
                for (var i = 0; i < $fadeInTarget.length; i++) {
                    new Waypoint({
                        element: $fadeInTarget[i],
                        handler: function handler(direction) {
                            if (direction == "down") {
                                TweenMax.to(this.element, 1, {
                                    opacity: 1,
                                    ease: Sine.easeOut
                                });
                                TweenMax.from(this.element, 1, {
                                    yPercent: 25,
                                    ease: Sine.easeOut
                                });
                            } else if (direction == "up") {
                                TweenMax.to(this.element, 0.5, {
                                    opacity: 0,
                                    ease: Sine.easeOut
                                });
                            }
                        },
                        offset: "80%"
                    });
                }

                var $fadeInTargetFromLeft = document.querySelectorAll(".fade-in-target-left");
                for (var j = 0; j < $fadeInTargetFromLeft.length; j++) {
                    new Waypoint({
                        element: $fadeInTargetFromLeft[j],
                        handler: function handler(direction) {
                            if (direction == "down") {
                                TweenMax.to(this.element, 1, {
                                    opacity: 1,
                                    ease: Sine.easeOut
                                });
                                TweenMax.from(this.element, 1, {
                                    xPercent: -25,
                                    ease: Sine.easeOut
                                });
                            } else if (direction == "up") {
                                TweenMax.to(this.element, 0.5, {
                                    opacity: 0,
                                    ease: Sine.easeOut
                                });
                            }
                        },
                        offset: "80%"
                    });
                }

                var $fadeInTargetFromRight = document.querySelectorAll(".fade-in-target-right");
                for (var k = 0; k < $fadeInTargetFromRight.length; k++) {
                    new Waypoint({
                        element: $fadeInTargetFromRight[k],
                        handler: function handler(direction) {
                            if (direction == "down") {
                                TweenMax.to(this.element, 1, {
                                    opacity: 1,
                                    ease: Sine.easeOut
                                });
                                TweenMax.from(this.element, 1, {
                                    xPercent: 25,
                                    ease: Sine.easeOut
                                });
                            } else if (direction == "up") {
                                TweenMax.to(this.element, 0.5, {
                                    opacity: 0,
                                    ease: Sine.easeOut
                                });
                            }
                        },
                        offset: "80%"
                    });
                }
            };

            return {
                init: init
            };
        })();

        // if browser is Internet Explorer add opacity to fade-in-targets
        // and do not initialize the fade-in functionality
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            $fadeInTargets.each(function() {
                $(this).addClass('opacity-full');
            });
            $fadeInTargetsL.each(function() {
                $(this).addClass('opacity-full');
            });
            $fadeInTargetsR.each(function() {
                $(this).addClass('opacity-full');
            });
            // else initialize fade-in-target functionality
        } else {
            fadeInTargets.init();
        }

    }); // document.ready
})(jQuery);
