/**
 * Homepage JS
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $home = $('.home');
        // sanity check
        if ($home.length < 1) {
            return;
        }

        var homepageModule = (function() {

            var bannerH1 = document.querySelector('header.banner h1');
            var bannerCta = document.querySelector('header.banner a');

            var init = function() {

				// setup homepage heading to be animated in
                TweenMax.set(bannerCta, {
                    autoAlpha: 0,
                    xPercent: -50
                });

                var mySplitText = new SplitText(bannerH1, {
                    type: 'words, chars'
                });

                var $words = mySplitText.words;

                $words.forEach(function(div) {
                    TweenMax.set(div, {
                        autoAlpha: 0,
                        xPercent: -50
                    });
                });

				// after 2.5s animate the heading 
                setTimeout(function() {
                    TweenMax.staggerFromTo($words, 0.75, {
                        autoAlpha: 0,
                        xPercent: -50
                    }, {
                        xPercent: 0,
                        autoAlpha: 1,
                        force3D: true,
                        ease: Sine.easeOut
                    }, 0.08);
                    TweenMax.to(bannerCta, 0.75, {
                        delay: 0.3,
                        xPercent: 0,
                        autoAlpha: 1,
                        force3D: true,
                        ease: Sine.easeOut
                    });
                }, 2500);

            };

            return {
                init: init
            };
        })();

        homepageModule.init();

    }); // document.ready
})(jQuery);
