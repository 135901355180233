/**
 * Slider
 *
 * @since   1.0.0
 * @package leland-lifestyles
 */

(function($) {
    $(document).ready(function() {

        var $sliderExists = $('.main-carousel');
        // sanity check
        if ($sliderExists.length < 1) {
            return;
        }

        var sliderModule = (function() {

            var init = function() {
                var $slider = document.querySelector('.main-carousel');
				// initialize flickity slider
                var flkty = new Flickity($slider, {
                    cellAlign: 'left',
                    contain: true,
                    wrapAround: true,
                    draggable: true,
                    autoPlay: 6000,
                    pageDots: false,
                });
                var arrowRight = document.querySelector('.next');
                var arrowLeft = document.querySelector('.previous');
                var arrowRightSvg = arrowRight.firstElementChild;
                var arrowLeftSvg = arrowLeft.firstElementChild;
				// remove old slider arrows
                arrowLeft.removeChild(arrowLeftSvg);
                arrowRight.removeChild(arrowRightSvg);
				// define new slider arrows
                var newArrowLeftSvg = '<svg width="3px" height="17px" viewBox="0 0 6 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g transform="translate(-21.000000, -1718.000000)" stroke="#FFFFFF"> <g transform="translate(15.000000, 1639.000000)"> <g transform="translate(9.000000, 88.000000) rotate(-180.000000) translate(-9.000000, -88.000000) translate(0.000000, 68.000000)"> <polyline points="6.84 12 11.1673828 20.1853693 6.84 28.2112926"></polyline> </g> </g> </g> </g></svg>';
                var newArrowRightSvg = '<svg width="3px" height="17px" viewBox="0 0 6 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g transform="translate(-284.000000, -1719.000000)" stroke="#FFFFFF"> <g transform="translate(15.000000, 1639.000000)"> <g transform="translate(263.000000, 68.000000)"> <polyline points="6.84 12 11.1673828 20.1853693 6.84 28.2112926"></polyline> </g> </g> </g> </g></svg>';
				// add new slider arrows
                arrowLeft.insertAdjacentHTML('beforeend', newArrowLeftSvg);
                arrowRight.insertAdjacentHTML('beforeend', newArrowRightSvg);

				// if screen is larger then 640px add slider button hover effects
                if (window.innerWidth >= 640) {
                    arrowRight.addEventListener('mouseenter', function(e) {
                        e.preventDefault();
                        var arrow = this.firstElementChild;
                        TweenMax.to(arrow, 0.3, {
                            x: 5,
                            ease: Sine.easeOut
                        });
                    });
                    arrowRight.addEventListener('mouseleave', function(e) {
                        e.preventDefault();
                        var arrow = this.firstElementChild;
                        TweenMax.to(arrow, 0.3, {
                            x: 0,
                            ease: Sine.easeOut
                        });
                    });
                    arrowLeft.addEventListener('mouseenter', function(e) {
                        e.preventDefault();
                        var arrow = this.firstElementChild;
                        TweenMax.to(arrow, 0.3, {
                            x: -5,
                            ease: Sine.easeOut
                        });
                    });
                    arrowLeft.addEventListener('mouseleave', function(e) {
                        e.preventDefault();
                        var arrow = this.firstElementChild;
                        TweenMax.to(arrow, 0.3, {
                            x: 0,
                            ease: Sine.easeOut
                        });
                    });
                }
            };

            return {
                init: init
            };
        })();

        sliderModule.init();

    }); // document.ready
})(jQuery);
